<template>
  <div class="luckdraw_list">
    <img src="../../assets/luckdraw_new/list-bg.png" class="bg" />
    <div class="list_box">
      <div v-for="(item, index) in prize_list" :key="index" class="item_box">
        <div class="item">
          <!-- 1.卡卷  2.优惠卷  3.积分 4.实物 -->
          <img src="../../assets/luckdraw_new/liat-jifen.png" class="img" v-if="item.is_coupon == 3" />
          <img src="../../assets/luckdraw_new/list-xuni.png" class="img"
            v-if="item.is_coupon == 1 || item.is_coupon == 2" />
          <img src="../../assets/luckdraw_new/list-shiwu.png" class="img" v-if="item.is_coupon == 4" />
          <div class="prize_info">
            <p class="prize_name">
              {{ item.prize_name }}
              <span v-if="item.number == 2">X2</span>
            </p>
            <span class="prize_name_en">{{ item.prize_name_en }}{{ item.number == 2 ? '*2' : '' }}</span>
            <span class="add_time" v-if="item.is_coupon == 3">将于3个工作日发放到您账户</span>
            <span class="add_time">参与时间:{{ item.add_time | filter_length }}</span>
            <span class="add_time_en" v-if="item.is_coupon == 3">Prize will be sent in 3 working days</span>
          </div>
        </div>
        <div class="prize_btn" v-if="item.is_coupon != 3">
          <template v-if="item.is_coupon == 4">
            <!-- 实物物品状态 -->
            <img class="top_status" src="../../assets/luckdraw_new/btn8.png" v-if="item.is_over == 1" />
            <img v-if="item.status == 1 && item.is_over == 0" @click="setCity(item)" class="btn_status"
              src="../../assets/luckdraw_new/btn4.png" />
            <img v-if="item.status == 2" @click="godetails(item)" class="btn_status"
              src="../../assets/luckdraw_new/btn6.png" />
            <img v-if="item.status == 2" @click.stop="click_wuliu(item.id)" class="btn_status"
              src="../../assets/luckdraw_new/btn5.png" />
          </template>
          <template v-else-if="item.is_coupon == 1">
            <img class="btn_status" @click="godetails(item)" src="../../assets/luckdraw_new/btn7.png"
              v-if="item.status == 1" />
            <img class="top_status" src="../../assets/luckdraw_new/btn9.png" v-if="item.status == 2" />

            <img v-if="item.status == 2" @click="godetails(item)" class="btn_status"
              src="../../assets/luckdraw_new/btn6.png" />
          </template>
          <template v-else>
            <img v-if="item.is_coupon != 3" @click="godetails(item)" class="btn_status"
              src="../../assets/luckdraw_new/btn6.png" />
          </template>
        </div>
      </div>
      <div class="go_luckdrawbtn-d">
        <div class="go_luckdrawbtn" @click="go_luckdrawbtn">
          <span class="btn_one_ch">返回</span>
          <span class="btn_one_en">Back</span>
        </div>
      </div>
    </div>
    <div class="layer-bg" v-if="show_page_add">
      <div class="layer">
        <div class="hint_title">填写收货地址</div>
        <div class="hint_en">Fill in your address</div>
        <div class="formItem formItemfirst">
          <div class="label">
            <div class="zh">姓名</div>
            <div class="en">Name</div>
          </div>
          <input type="text" class="value" placeholder="请输入收件人姓名" v-model="params.send_username" />
        </div>
        <div class="formItem">
          <div class="label">
            <div class="zh">手机</div>
            <div class="en">Tel</div>
          </div>
          <input type="tel" class="value" placeholder="请输入收件人手机号码" maxlength="11" v-model="params.send_phone" />
        </div>
        <div class="formItem">
          <div class="label">
            <div class="zh">城市</div>
            <div class="en">City</div>
          </div>
          <van-cell class="value" is-link @click="show_address = true">
            <span v-if="!params.send_province">所在省市区</span>
            <span style="line-height: 20px" v-else>{{ params.send_province }}{{ params.send_city
            }}{{ params.send_area }}</span>
          </van-cell>
        </div>
        <div class="formItem areatext">
          <div class="label">
            <div class="zh">详细地址</div>
            <div class="en">Address</div>
          </div>
          <textarea class="value textarea" cols="30" rows="10" placeholder="请填写详细地址"
            v-model="params.send_addr"></textarea>
        </div>
        <p class="hintL">
          请在中奖后7天内填写地址，逾期失效。
        </p>
        <p class="hintL hintL-en">
          Please fill in address in 7 days after you win the prize, otherwise the prize will turn invalid.</p>
        <div class="layer-btns ">
          <div class="btns btn2 bigbtn" @click="submit()">
            <span class="btn_one_ch">领取</span>
            <span class="btn_one_en">Claim</span>
          </div>
        </div>
        <img src="../../assets/luckdraw_new/close.png" class="layer-close" @click="show_page_add = false" />
      </div>
    </div>
    <div class="layer-bg" v-if="show_wuliu">
      <div class="layer" :style="!wuliu_list ? { 'padding': '0px' } : ''">
        <div class="wuliu_tit" v-if="wuliu_list">物流详情</div>
        <div class="wuliu_express_number" v-if="wuliu_list">
          <span>物流信息：{{ wuliu_list.express.express_number }}</span>
        </div>
        <div class="wuliu_b_list" v-if="wuliu_list">
          <van-steps direction="vertical" :active="0" active-color="#00005F">
            <van-step v-for="(item, index) in wuliu_list.express_msg" :key="index" class="wuliu_b_item">
              <p class="item_content">{{ item.context }}</p>
              <p class="item_time">{{ item.time }}</p>
            </van-step>
          </van-steps>
        </div>
        <div v-if="!wuliu_list" style="width: 100%; text-align: center">
          <img src="../../assets/luckdraw_new/no_wuliu.png" style="width: 100%" alt="" />
        </div>
        <img src="../../assets/luckdraw_new/close.png" class="layer-close" @click="show_wuliu = false" />
      </div>
    </div>
    <van-popup v-model="show_address" position="bottom">
      <van-area :area-list="areaList" :value="value" confirm-button-text=" " cancel-button-text=" "
        @cancel="show_address = false" @confirm="click_address" />
    </van-popup>
  </div>
</template>
<script>
var qs = require("qs");
import AreaList from "../../assets/area.js";
export default {
  name: "index",
  props: {
    msg: String,
  },
  data() {
    return {
      is_double: -1, //0正常模式，>0是双倍模式
      wuliu_list: "",
      show_wuliu: false,
      show_address: false,
      value: "",
      show_page_add: false,
      areaList: AreaList,
      params: {
        ut: "",
        id: "",
        send_username: "",
        send_phone: "",
        send_province: "",
        send_city: "",
        send_area: "",
        send_addr: "",
      },
      prize_list: "",
    };
  },
  created() {
    this.is_double = localStorage.getItem("is_double");
    this.getprize_list();
    if (localStorage.getItem("addres")) {
      this.getUserAddress();
    }
  },
  methods: {
    // 默认地址
    getUserAddress() {
      // 请求用户地址
      this.$axios({
        method: "post",
        url: this.$url + "/Api/User/getUserAddress",
        data: qs.stringify({
          ut: localStorage.getItem("ut"),
        }),
      }).then((res) => {
        if (res.data.code == 1) {
          var getUserAddress = res.data.data;
          localStorage.setItem(
            "getUserAddress_diamond",
            JSON.stringify(getUserAddress.diamond)
          );
          localStorage.setItem(
            "getUserAddress_normal",
            JSON.stringify(res.data.data.normal)
          );
          var getUserAddress = JSON.parse(
            localStorage.getItem("getUserAddress_normal")
          );
          this.params.send_username = getUserAddress.send_username || "";
          this.params.send_phone = getUserAddress.send_phone || "";
          this.params.send_province = getUserAddress.send_province || "";
          this.params.send_city = getUserAddress.send_city || "";
          this.params.send_area = getUserAddress.send_area || "";
          this.params.send_addr = getUserAddress.send_addr || "";
        }
      });
    },
    click_wuliu(id) {
      var data = qs.stringify({
        ut: localStorage.getItem("ut"),
        userid: localStorage.getItem("userid"),
        id: id,
        show_type: 3,
      });
      this.$axios({
        method: "post",
        url: this.$url + "/Api/Wuliu/getWuliu",
        data: data,
      }).then((res) => {
        console.log("order_list", res);
        this.show_wuliu = true;
        if (res.data.code == 1) {
          this.wuliu_list = res.data.data;
        } else {
          this.wuliu_list = "";
        }
      });
    },
    setCity(item) {
      var that = this;
      this.params.id = item.id;
      if (this.is_double == 3 && item.is_super == 1) {
        //锦鲤大礼包
        this.$router.replace({
          path: "/luckdrawNewYear_new",
          query: { id: item.id },
        });
      } else {
        this.show_page_add = true;
      }
    },
    click_address(e) {
      console.log(e);
      this.params.send_province = e[0].name;
      this.params.send_city = e[1].name;
      this.params.send_area = e[2].name;
      //this.params.code = e[2].code;
      //this.params.value = e[2].code;
      this.show_address = false;
    },
    //领取实物
    submit() {
      if (!this.params.send_username) {
        this.$toast({
          mask: true,
          message: "请输入姓名",
          zIndex: 999999,
        });
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.params.send_phone)) {
        this.$toast({
          mask: true,
          message: "请输入正确的手机号",
          zIndex: 999999,
        });
        return;
      }
      if (!this.params.send_province) {
        this.$toast({
          mask: true,
          message: "请选择省市区",
          zIndex: 999999,
        });
        return;
      }
      if (!this.params.send_addr) {
        this.$toast({
          mask: true,
          message: "请输入详细地址",
          zIndex: 999999,
        });
        return;
      }
      console.log(
        JSON.stringify({
          ...this.params,
          ut: localStorage.getItem("ut"),
        })
      );
      this.$axios({
        method: "post",
        url: this.$url + "/Api/Score/add_address",
        data: qs.stringify({
          ...this.params,
          ut: localStorage.getItem("ut"),
        }),
      }).then((res) => {
        if (res.data.code == 1) {
          this.show_page_add = false;
          this.$router.replace({
            path: "/H5_luckdrawEntity_new",
            query: {
              ...this.params,
              ...this.actives,
            },
          });
        } else {
          this.$toast({
            message: res.data.msg,
            zIndex: 999999,
          });
        }
      });
    },
    get_juan(item) {
      console.log(item);
      var that = this;
      var data = qs.stringify({
        ut: localStorage.getItem("ut"),
        userid: localStorage.getItem("userid"),
        prize_id: item.prize_id,
        id: item.id,
      });
      this.$axios({
        method: "post",
        url: this.$url + "/Api/Score/save_info",
        data: data,
      }).then((res) => {
        console.log(res);
        that.$toast({
          mask: true,
          message: "领取成功",
          zIndex: 999999,
        });
        that.getprize_list();
        // this.userinfo = res.data.data;
      });
    },
    godetails(item) {
      localStorage.setItem("prize", JSON.stringify(item));
      if (item.is_coupon == 1) {
        this.$router.replace({ path: "/H5_luckdrawdetails_new" });
      } else {
        this.$router.replace({
          path: "/H5_luckdrawEntity_new",
          query: { id: item.id },
        });
      }
    },
    go_luckdrawbtn() {
      this.$router.replace({ path: "/H5_luckdraw_new" });
      // this.$router.go(-1);
    },
    getprize_list() {
      var data = qs.stringify({
        ut: localStorage.getItem("ut"),
        userid: localStorage.getItem("userid"),
      });
      this.$axios({
        method: "post",
        url: this.$url + "/Api/Score/prize_list",
        data: data,
      }).then((res) => {
        console.log(res);
        this.prize_list = res.data.data;
      });
    },
  },
  filters: {
    filter_length(e) {
      // console.log(this.show123)
      e = e.replace(/-/g, ".");
      return e.substring(0, e.length - 3);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import"../../assets/H5.css";

.van-picker__cancel {
  width: 118px !important;
  height: 42px !important;
  background-image: url("../../assets/luckdraw_new/add_no.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: 30px;
}

/* van-button van-button--default van-button--large van-dialog__confirm van-hairline--left */
.van-picker__confirm {
  width: 118px !important;
  height: 42px !important;
  background-image: url("../../assets/luckdraw_new/add_yes.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 30px;
}

.van-steps__items {
  height: 60vh;
  overflow-y: scroll;
}

.van-step--vertical {
  line-height: 28px;
}
</style>
<style scoped >
.hintL {
  text-align: left;
  width: 100%;
  font-size: 26px;
}

.hintL-en {
  font-size: 20px;
  margin-top: 13px;
}

.wuliu_b_list {
  line-height: 24px;
}

.go_luckdrawbtn-d {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.go_luckdrawbtn {
  width: 686px;
  height: 80px;
  background: #00005F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  line-height: 1;
  border-radius: 20px 0px 20px 0px;
}

.btn_one_ch {
  font-size: 32px;
  font-weight: bold;
}

.btn_one_en {
  margin-top: 6px;
  font-size: 20px;
}

.bg {
  width: 100%;
  height: auto;
}

.list_box {
  width: 100%;
  position: absolute;
  top: 205px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 128px;

}

.item_box {
  box-sizing: border-box;
  width: 686px;
  background: #FFFFFF;
  box-shadow: 0px 4px 56px 0px rgba(0, 0, 0, 0.0400);
  border-radius: 32px;
  margin-bottom: 24px;
  position: relative;
}

.item {
  box-sizing: border-box;
  width: 686px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 31px;
}

.img {
  align-self: start;
  margin-top: 28px;
  width: 96px;
  height: 96px;
}

.prize_info {
  box-sizing: border-box;
  display: flex;
  padding: 27px 0;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 18px;
  line-height: 30px;
}

.prize_name {
  font-size: 28px;
  color: #010063;
  font-weight: bold;
}

.prize_name_en {
  font-weight: normal;
  font-size: 20px;
  color: #BFBDC0;
  margin-top: 12px;
  margin-bottom: 20px;
}

.add_time {
  font-size: 24px;
  color: #B7B5B9;
  margin-bottom: 12px;
  line-height: 1;
}

.add_time_en {
  font-size: 20px;
  color: #B7B5B9;
}

.top_status {
  width: 118px;
  height: 51px;
  position: absolute;
  top: 0;
  right: 0;
}

.prize_btn {
  box-sizing: border-box;
  border-top: 1px solid #F5F5F5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 31px;
  line-height: 1;

}

.btn_status {
  height: 50px;
  width: auto;
  margin: 24px 0 24px 18px;
}

.mt30 {
  margin-top: 30px;
}

.formItem {
  box-sizing: border-box;
  width: 576px;
  height: 76px;
  background: #EDEDFA;
  box-shadow: 0px 4px 56px 0px rgba(0, 0, 0, 0.0400);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 30px;
}

.formItemfirst {
  margin-top: 47px;
}

.label {
  display: flex;
  flex-direction: column;
}

.label .zh {
  font-size: 26px;
}

.label .en {
  line-height: 1;
  font-size: 18px;
}

.value {
  font-size: 26px;
  text-align: right;
  flex: 1;
  margin-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  background: none;
  border: none;
}

.areatext {
  box-sizing: border-box;
  height: 158px;
  align-items: flex-start;
  padding-top: 17px;
}

.textarea {
  padding-top: 11px;
  height: 75%;
}

.value span {
  display: block;
  flex: 1;
  text-align: right;
}

.layer {
  box-sizing: border-box;
  width: 640px;
  padding: 48px 57px;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
}

.hint_title {
  font-size: 32px;
  font-weight: normal;
}

.hint_zh {
  font-size: 26px;
  font-weight: normal;
}

.layer-close {
  position: absolute;
  bottom: -120px;
  width: 62px;
  height: 62px;
}

.layer-btns {
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layer-btns .btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px 0px 20px 0px;
  margin: 0 31px;
  align-items: center;
  width: 232px;
  height: 72px;
  border: 2px solid #00005F;
  color: #00005F;
  line-height: 1;

}

.layer-btns .bigbtn {
  width: 312px;
  height: 72px;
}

.layer-btns .btn2 {
  background: #00005F;
  color: #fff;
}

.btn_one_zh {
  font-size: 28px;
  font-weight: normal;
}

.btn_one_en {
  font-size: 22px;
  font-weight: normal;
}
</style>
  